import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link, useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import {appConst} from "../constant/appConst";



function ClientLogin() {

  const [fields, setFields] = useState({email:"",password:""});
  const [errors, setErrors] = useState({email:"",password:""});
  const [isLoading,setIsLoading] = useState(false);
  const { alert, setAlert } = useContext(AlertContext);
  const { client, setClient } = useContext(ClientContext);
  const { user, setUser } = useContext(UserContext);
  const { favs, setFavs } = useContext(FavContext);
  const history = useHistory();

  useEffect(() => {
    if(client)
    {

      setFavs(client.favs);
      sessionStorage.setItem("favs",JSON.stringify(client.favs));

      apiConst.presentationCreate(client.user.id,client.id,0).then(result => {
        if(result.response)
        {
          sessionStorage.setItem("client",JSON.stringify(client));
          sessionStorage.setItem("presentation_id",result.presentation.id);
          sessionStorage.setItem("presentation_status",result.presentation.status);
          setAlert("Hello " + client.first_name + " " + client.last_name);
          history.push("/");


        }
      })
    }

  },[client]);

  useEffect(() => {
    if(user)
    {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("presentation_id");
      sessionStorage.removeItem("presentation_status");
      sessionStorage.removeItem("client");
      sessionStorage.removeItem("favs");
      sessionStorage.setItem("user",JSON.stringify(user));
    }

  },[user]);




  function onClickLogin(){
    if(handleValidation())
    {
      setIsLoading(true);
      apiConst.clientLogin(fields["email"],fields["password"]).then(result => {
        setIsLoading(false);

        if(result.response)
        {
          if(result.client.is_verified) {
            if(result.client.lead_status_id === appConst.lead_status.active) {
              setUser(result.client.user);
              setClient(result.client);
            }
            else {
              errors["email"] = "Self login isn't allowed for you. Please contact with sales team to get permission";
              errors["password"] = "";
              setErrors(errors);
              setIsLoading(false);
            }
          }
          else
          {
            history.push("/verify/" + result.client.id)
          }

        }
        else
        {
          errors["email"] = "Email address or password is wrong";
          errors["password"] = "";
          setErrors(errors);
          setIsLoading(false);
        }

      })
    }

  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["email"])
    {
      formIsValid = false;
      errors["email"] = "Please type your email";
    }
    else
    {
      if(typeof fields["email"] !== "undefined"){
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
      }
    }

    if(!fields["password"]){
      formIsValid = false;
      errors["password"] = "Please type password";
    }

    setErrors(errors)
    return formIsValid;
  }

  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }


  return (
    <section  className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <div className="logo"><img src={require("../img/logo-splash.svg")} /></div>
          <h2>CLIENT LOGIN</h2>
          <div className="form-element">
            <input type="text" className={errors["email"] ? "form-control is-invalid" : "form-control"}  id="email" placeholder="E-mail" onChange={(e) => handleChange("email",e)} />
            {
              errors["email"] && <div className="invalid-feedback">
                {errors["email"]}
              </div>
            }
          </div>

          <div className="form-element">
          <input type="password" className={errors["password"] ? "form-control is-invalid" : "form-control"} id="password" placeholder="Password" onChange={(e) => handleChange("password",e)} />
          {
            errors["password"] && <div className="invalid-feedback">
              {errors["password"]}
            </div>
          }
          </div>
          <div className="mt-4">
            <ButtonWithLoading className={"default-button login"} onClick={onClickLogin} label="LOGIN" isLoading={isLoading} />
          </div>
          <div className="mt-3 ">
            <Link to="/forgotPassword">Forgot Password ?</Link>
          </div>
          <div className="mt-4 ">
            <div className="seperator-text">
              OR
            </div>
          </div>
          <div className="mt-4">
            <Link className={"default-button lined login"} to="/register">REGISTER</Link>
          </div>

        </div>

    </div>
      {!user && <Link to="/salesLogin" className="sales-login-link">Login as Sales Person</Link>}
      {user && <Link to="/" className="sales-login-link">Go Home</Link>}
    </section>
  );
}

export default ClientLogin;