import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router} from "react-router-dom";

import './css/App.css';

import UserContext from "./components/context/UserContext.js";
import FilterContext from "./components/context/FilterContext.js";
import AppDataContext from "./components/context/AppDataContext";
import {apiConst} from "./constant/apiConst";
import AlertContext from "./components/context/AlertContext";
import ClientContext from "./components/context/ClientContext";
import FavContext from "./components/context/FavContext";
import Routes from "./components/Routes";
import ConfirmContext from "./components/context/ConfirmContext";
import LightBoxContext from "./components/context/LightBoxContext";
import {appSettings} from "./constant/appConst";
import LocationContext from "./components/context/LocationContext";
import FavCompareContext from "./components/context/FavCompareContext";

export default function App() {

  const filterDefaultValues = {
    floorType: [],
    buildingType: false,
    priceMin: appSettings.priceFilter.min,
    priceMax: appSettings.priceFilter.max,
    status: false,
  };

  const appDataDefaultValues = {
    isFilterModeActive: true,
    filterModePrevStatus : false,
    properties : null,
    filteredProperties : null,
    buildings : null,
    leadSources : null,
    leadStatuses : null,
    roomTypes :null,
    statuses : null,
    buildingTypes:null,
    loaded : false,
  };


  const [filter, setFilter] = useState(filterDefaultValues);
  const filterValue = { filter, setFilter };

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')) || null);
  const userValue = { user, setUser };

  const [client, setClient] = useState(JSON.parse(sessionStorage.getItem('client')) || null);
  const clientValue = { client, setClient };

  const [favs, setFavs] = useState(JSON.parse(sessionStorage.getItem('favs')) || []);
  const favsValue = { favs, setFavs };

  const [appData, setAppData] = useState(appDataDefaultValues);
  const appDataValue = { appData, setAppData };

  const [alert, setAlert] = useState(null);
  const alertValue = { alert, setAlert };

  const [confirm, setConfirm] = useState({show:false,text:"",onConfirm:null,onUnconfirm:null,question:""});
  const confirmValue = { confirm, setConfirm };

  const [lightBox, setLightBox] = useState(false);
  const lightBoxValue = { lightBox, setLightBox };

  const [is360Loaded,setIs360Loaded] = useState(false);

  const [locationCategory, setLocationCategory] = useState("all");
  const locationCategoryValue = { locationCategory, setLocationCategory };

  const [favCompareList, setFavCompareList] = useState([]);
  const favCompareListValue = { favCompareList,setFavCompareList };

  useEffect(() => {
    Promise.all([
      apiConst.getProperties(),
      apiConst.getBuildings(),
      apiConst.getLeadSources(),
      apiConst.getLeadStatuses(),
      apiConst.getRoomTypes(),
      apiConst.getStatuses(),
      apiConst.getBuildingTypes(),
    ]).then(([properties, buildings, leadSources,leadStatuses,roomTypes,statuses,buildingTypes]) => {

      let newAppData = {...appData};
      newAppData.properties = newAppData.filteredProperties = properties;
      newAppData.buildings = buildings;
      newAppData.leadSources = leadSources;
      newAppData.leadStatuses = leadStatuses;
      newAppData.roomTypes = roomTypes;
      newAppData.statuses = statuses;
      newAppData.buildingTypes = buildingTypes;
      newAppData.loaded = true;
      newAppData.buildingStatuses = getBuildingStatuses(newAppData);
      newAppData.getFreshBuildingStatuses = getFreshBuildingStatuses;
      newAppData.floorStatuses = getFloorStatuses(newAppData);
      newAppData.getFreshFloorStatuses = getFreshFloorStatuses;
      window.updateBuildingStatuses(newAppData.buildingStatuses);

      setAppData(newAppData);

    }).catch((err) => {
      console.log(err);
    });

    document.title = appSettings.projectName;
    
  },[])

  function getFreshBuildingStatuses(){
    return getBuildingStatuses(this)
  }
  function getFreshFloorStatuses(){
    return getFloorStatuses(this)
  }
  function getFloorStatuses(newAppData){

    let floorStatuses = {};

    newAppData.buildings.forEach(building => {

      let flatLevels = [];
      let statuses = [];
      let propertyIds = [];

      

      if (building.is_villa) {

        let filteredProperties = newAppData.properties.filter((item)=>{

          if (item.building_id == building.id) {

            return true;
            
          }
  
          return false;
  
        });

        filteredProperties.forEach((item)=>{
          statuses.push({propertyId:item.id,status: item.status.id});
        });

      }
      else {

        let filteredProperties = newAppData.properties.filter((item)=>{

          if (item.building_id == building.id) {
            
            if (!flatLevels.includes(item.flatLevel))
              flatLevels.push(item.flatLevel);

            if (!propertyIds.includes(item.id))
              propertyIds.push(item.id);

            
            if (item.status.id != 3) {
              return true;
            }
  
          }
  
          return false;
  
        });

        let reducedFilteredProperties = filteredProperties.reduce(function(storage, item) {

          if(!storage.includes(item.flatLevel)) {
            storage.push(item.flatLevel);
          }

          return storage;

        }, []);


        flatLevels.forEach((item,key)=>{

          if(reducedFilteredProperties.includes(item)) {

            statuses.push({propertyId:propertyIds[key],status: filteredProperties.find(property => {
                return property.id === propertyIds[key]
              }).status_id});

          }
          else {

            statuses.push({propertyId:propertyIds[key],status: 3});

          }

        });
  
        
      }

      floorStatuses[building.code] = statuses;

    }); 

    return floorStatuses;

  }

  function getBuildingStatuses(newAppData){

    let soldBuildings = [];
    let optionBuildings = [];
    

    if (newAppData.buildings) {
      
      newAppData.buildings.forEach(building => {

        let filteredProperties = [];
        let hasSingleProperty = false;

        if (building.is_villa) {

          filteredProperties = newAppData.properties.filter((item)=>{

            if (item.building_id === building.id) {
              
              return true;
              
            }
    
            return false;
    
          });

          if (filteredProperties.length == 1) {
            
            hasSingleProperty = true;

          }

        }

        if (hasSingleProperty) {
          
          const property = filteredProperties[0];
          
          if (property.status.id == 2) {
            
            optionBuildings.push(building.id);

          }
          else if (property.status.id == 3) {
            
            soldBuildings.push(building.id);

          }
          
        }
        else {

          let isSold = true;
          let isReserved = true;


          for (let i = 0; i < newAppData.properties.length; i++) {
            const property = newAppData.properties[i];

              if (property.building_id == building.id && property.status.id != 3) {
                isSold = false;
                break;
              }

          }

          for (let i = 0; i < newAppData.properties.length; i++) {
            const property = newAppData.properties[i];

              if (property.building_id == building.id && property.status.id != 2) {
                isReserved = false;
                break;
              }

          }

          if (isSold) soldBuildings.push(building.zone_id == window.zone ? building.id : null);
          if (isReserved) optionBuildings.push(building.zone_id == window.zone ? building.id : null);

        }

      });

      return {
        soldBuildings,
        optionBuildings,
      };
      
    }
    
  }

  window.addEventListener('zoneChanged',function(){
    console.log("zone değişti")
    setIs360Loaded(false);


  },{once:true})

  window.addEventListener('load360',function(){
    if(appData.loaded) {
      console.log("360 yüklendi")
      setIs360Loaded(true);
      appData.buildingStatuses = appData.getFreshBuildingStatuses();
      window.updateBuildingStatuses(appData.buildingStatuses);
    }
  },{once:true})



  return (
    <div>
      <UserContext.Provider value={userValue}>
        <ClientContext.Provider value={clientValue}>
          <FilterContext.Provider value={filterValue}>
            <AppDataContext.Provider value={appDataValue}>
              <AlertContext.Provider value={alertValue}>
                <ConfirmContext.Provider value={confirmValue}>
                  <LightBoxContext.Provider value={lightBoxValue}>
                    <FavContext.Provider value={favsValue}>
                      <FavCompareContext.Provider value={favCompareListValue} >
                      <LocationContext.Provider value={locationCategoryValue}>
                        <Router basename={process.env.PUBLIC_URL}>
                          <Routes is360Loaded={is360Loaded} alert={alert} />
                        </Router>
                      </LocationContext.Provider>
                      </FavCompareContext.Provider>
                  </FavContext.Provider>
                  </LightBoxContext.Provider>
              </ConfirmContext.Provider>
              </AlertContext.Provider>
            </AppDataContext.Provider>
          </FilterContext.Provider>
        </ClientContext.Provider>
      </UserContext.Provider>
    </div>
    
  );
}