import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import '../../css/dashboard/SalesRep.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import ModalEditSalesPerson from './ModalEditSalesPerson.js';
import ModalAssignClient from './ModalAssignClient.js';
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import LoadingOverlay from "../LoadingOverlay";

function Broker(props) {
  const params = useParams();
  const[person, setPerson] = useState(null);
  const [totals,setTotals] = useState({presentation : -1, client : -1, option : -1, sold : -1})
  const [data1,setData1] = useState(null);
  const [data2,setData2] = useState(null);
  const [data3,setData3] = useState(null);
  const [data4,setData4] = useState(null);
  const [refreshToken,setRefreshToken] = useState(null);

  useEffect(() => {
    setTotals({presentation : -1, client : -1, option : -1, sold : -1})
    apiConst.getUser(params.user_id).then(user => {
      setPerson(user);

      let data_1 = {
        className: "property-views",
        title: "Property Views",
        headings: ["Date", "Property", "Views"],
        rows: []
      };
      user.views.forEach(view => {
        data_1.rows.push({
          date : view.date,
          property : {id : view.property.id,name: view.property.code},
          views : view.view_count

        })
      })
      setData1(data_1);

      let data_2 = {
        className: "sales-rep-property-actions",
        title: "Property Actions",
        headings: ["Date", "Property", "Client",  "Status"],
        rows: []
      };
      user.actions.forEach(action => {
        data_2.rows.push({
          date : action.date,
          property : {id : action.property.id,name: action.property.code},
          client: {id : action.client.id,name:action.client.first_name +" "+action.client.last_name},
          status : appConst.getStatusTitle(action.status_id)
        })
      })
      setData2(data_2);

      let data_3 = {
        className: "client-lead-status",
        title: "Clients",
        headings: ["Client", "Lead Status"],
        rows: []
      };
      user.clients.forEach(client => {
        data_3.rows.push({ client: {id: client.id, name: client.first_name +" "+client.last_name}, leadStatus: client.lead_status.label })
      })
      setData3(data_3);


      let data_4 = {
        className: "recent-presentations",
        title: "Recent Presentations",
        headings: ["Date", "Client"],
        rows: []
      };
      user.presentations.forEach(presentation => {
        data_4.rows.push({date: presentation.date, client: {id: presentation.client.id, name :presentation.client.first_name+" "+presentation.client.last_name}})
      })
      setData4(data_4);

      setTotals({
        presentation : user.totals.presentation,
        client : user.totals.client,
        option : user.totals.option,
        sold : user.totals.sold,
      });


    })
  },[refreshToken])


  function savePerson(process,savedPerson){

    if(process === "update")
      setPerson(savedPerson)

  }

  function assignClient(){
      setRefreshToken(Math.random());
  }



  return (

    <section className="primary dashboard sales-rep">
      
      <DashboardHeader />
      <LoadingOverlay loaded={!!person}/>
      {person && <div className="container">
        <div className="row">

          <div className="col-5">
            <div className="dashboard-box sales-rep-profile">

              <div className="header">
                <h3>{person.name}</h3>
                <div className="edit-button" onClick={() => appConst.showModal("edit-person")}>Edit</div>
              </div>

              <div className="row item">
                <div className="col-6">
                  <div>Phone</div>
                  <div>{person.phone}</div>
                </div>

                <div className="col-6 item">
                  <div>Email</div>
                  <div>{person.email}</div>
                </div>
              </div>

              
            </div>   

            <DashboardTable data={data1} />
            <DashboardTable data={data2} />

          </div>

          <div className="col-5">
            
            <DashboardTable data={data3} />
            <DashboardTable data={data4} />
            
          </div>

          <div className="col-2">

            <NumberBox className="number-box" number={totals.presentation}  text="Presentation" />
            <NumberBox className="number-box" number={totals.client}  text="Client" />
            <NumberBox className="number-box" number={totals.option}  text="Option" />
            <NumberBox className="number-box" number={totals.sold}  text="Sold" />

          </div> 
          
        </div>

        

      </div>}

      {person && <ModalEditSalesPerson title="Edit Sales Rep" person={person} onConfirm={savePerson} confirmText="Save" cancelText="Cancel" />}
      {person && <ModalAssignClient person={person} onConfirm={assignClient}  />}

    </section>

  );
  
}

export default Broker;