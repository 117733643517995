import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link,useParams,useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";



function ClientInvitationWait() {

  const {id} = useParams();

  const [fields, setFields] = useState({code:""});

  const { alert, setAlert } = useContext(AlertContext);
  const { client, setClient } = useContext(ClientContext);
  const { user, setUser } = useContext(UserContext);
  const { favs, setFavs } = useContext(FavContext);
  const history = useHistory();

  const [ minutes, setMinutes ] = useState(1);
  const [seconds, setSeconds ] =  useState(59);
  const [resendLinkDisable,setResendLinkDisable] = useState(false);
      useEffect(()=>{
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return ()=> {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if(client)
    {

      setFavs(client.favs);
      sessionStorage.setItem("favs",JSON.stringify(client.favs));

      apiConst.presentationCreate(client.user.id,client.id,1).then(result => {
        if(result.response)
        {
          sessionStorage.setItem("client",JSON.stringify(client));
          sessionStorage.setItem("presentation_id",result.presentation.id);
          sessionStorage.setItem("presentation_status",result.presentation.status);
          setAlert("Hello " + client.first_name + " " + client.last_name);
          history.push("/");

        }
      })
    }

  },[client]);

  useEffect(() => {
    if(user)
    {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("presentation_id");
      sessionStorage.removeItem("client");
      sessionStorage.removeItem("favs");
      sessionStorage.setItem("user",JSON.stringify(user));
    }

  },[user]);

  useEffect(()=> {
    let interval = setInterval(checkInvitation,5*1000);
    return ()=> {
      clearInterval(interval);
    };
  },[])


  function checkInvitation()
  {


      apiConst.clientInvitationCheck(id).then(result => {

        if(result.response)
        {
          setUser(result.user);
          setClient(result.client);
        }
        else {



        }
      })

    }

  function resendInvitationLink()
  {
    if(!resendLinkDisable) {
      apiConst.clientInvitationResend(id).then(result => {

        if(result.response)
        {
          setAlert("Invitation link has been send. Please check your email again")
          setMinutes(1);
          setSeconds(59)
          setResendLinkDisable(true)
          setTimeout(()=> {
            setResendLinkDisable(false)
          },30000)
        }
        else {



        }
      })
    }
    else {
      setAlert("Please wait 30 sec to resend link")
    }



  }





  return (
    <section className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <h2>WAITING CLIENT</h2>
          <p>Please wait for client accept your intivation</p>
          <div className={"text-center"}>
            { minutes === 0 && seconds === 0
                ? null
                : <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1>
            }
          </div>
          <div className="mt-4 ">
            <div className={"alert alert-secondary text-center"}>
              Please check your junk email in case you don't receive an email
            </div>
            <div className="seperator-text">
              OR
            </div>
          </div>
          <div className="mt-4">
            <button className={"default-button lined login mb-3"} onClick={resendInvitationLink}>RESEND LINK</button>
            <Link className={"default-button lined login"} to="/inviteClient">GO BACK</Link>
          </div>



        </div>




    </div>
    </section>
  );
}

export default ClientInvitationWait;