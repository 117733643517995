import React, {useContext, useEffect, useState} from 'react';
import AppDataContext from "./context/AppDataContext";
import {withRouter,useHistory} from "react-router-dom";
import FilterContext from "./context/FilterContext";
import PreviewImage from "./PreviewImage";
import AlertContext from "./context/AlertContext";
import UserContext from "./context/UserContext";
import ClientContext from "./context/ClientContext";
import ConfirmContext from "./context/ConfirmContext";
import {appSettings} from "../constant/appConst";
import FavContext from "./context/FavContext";
function MainView(props)
{
    const {alert,setAlert} = useContext(AlertContext);

    const { appData, setAppData} = useContext(AppDataContext);
    const { favs, setFavs} = useContext(FavContext);

    const { filter, setFilter} = useContext(FilterContext);
    const [previewImage, setPreviewImage] = useState(null);
    const {user,setUser} = useContext(UserContext);
    const {client,setClient} = useContext(ClientContext);
    const history = useHistory();
    function goBuildingPage(buildingName)
    {

        let id = 0;
        appData.buildings.map(building => {
            if(building.instance_name === buildingName) return id = building.id;
        });
        props.history.push("/building/"+ id);
    }


    useEffect(()=>{
        if(props.loaded360 && appData.loaded) {
            for (let i = 1; i < (window.totalBuilding + 1); i++ ) {
                window.exportRoot.hover["m"+window.zone+i].user = user;

            }
        }

    },[user])


    useEffect(() => {


        if(appData.loaded && props.loaded360)
        {

            for (let i = 1; i < (window.totalBuilding + 1); i++ ) {

                if(window.exportRoot) {

                    window.exportRoot.hover["m"+window.zone+i].addEventListener("click", function(e){
                        if(window.zone !== 0) {
                            if(!appData.buildingStatuses.soldBuildings.includes(i))
                            {
                                if(appSettings.showRegisterClientPage)
                                    (sessionStorage.getItem("user") !== null) ? goBuildingPage("b"+window.zone+i) : props.history.push("/register")
                                else
                                    goBuildingPage("b"+window.zone+i)

                            }

                            else
                                setAlert("Sorry! This building is completely sold");
                        }
                        else {

                            window.init(i)
                        }


                    });
                }



            }

        }

    },[props.loaded360,appData.loaded])



    useEffect(() => {

        if(appData.loaded) {
            if(appData.isFilterModeActive )
            {

                let filteredBuildings = [];
                appData.filteredProperties.forEach(function(properties){

                    filteredBuildings.push(window.zone === 0 ? "b0" + properties.zone_id : properties.building.instance_name);
                })

                let filteredBuildingBadgeMap = [];

                filteredBuildings.forEach(function(building){
                    //o bina ile ilk kez karşılaşıyor ise 1 oluyor değer
                    if(!filteredBuildingBadgeMap[building])
                        filteredBuildingBadgeMap[building] = 1;
                    // ondan sonraki her karşılaşmada o binanın değerini 1 artırıyoruz
                    else
                        filteredBuildingBadgeMap[building]++;
                })
                if(window.exportRoot && props.loaded360)
                {
                    window.isAllMarkerVisible = false;
                    window.toggleAllLabel();
                    window.setBadge(filteredBuildingBadgeMap);


                }

            }
            else
            {
                if(window.exportRoot && props.loaded360) window.clearBadge();
            }


            let timeOut
            for (let i = 1; i < (window.totalFacility + 1); i++ ) {
                let marker = window.exportRoot.hover["pm"+window.zone+i]
                marker.addEventListener("click", function(e){
                    if(appSettings.showRegisterClientPage)
                        (sessionStorage.getItem("user") !== null) ? history.push("/facility/"+ i) : history.push("/register")


                });
                marker.addEventListener("rollover", function(e){
                    console.log("evet mouse geldi")

                    setPreviewImage({
                        id:i,x:marker.x,y:marker.y
                    });

                    if(document.querySelector(".preview-image"))
                        document.querySelector(".preview-image").classList.add("show");

                    //props.history.push("/facility/"+ i);
                });
                marker.addEventListener("rollout", function(e){
                    console.log("evet mouse gitti")

                        if(document.querySelector(".preview-image"))
                            document.querySelector(".preview-image").classList.remove("show");


                });

            }
        }



    },[appData.filteredProperties,appData.isFilterModeActive,appData.loaded,props.loaded360])



    useEffect(()=> {

        if(props.loaded360) {
            if(client && favs) {
                let favBuildings = [];
                favs.forEach(function(fav) {
                    favBuildings.push(fav.property.building.instance_name);
                })
                if(window.exportRoot)
                {
                    window.setFavBadge(favBuildings);
                }

            }
            else {
                if(window.exportRoot) {
                    window.unsetFavBadge()
                }

            }
        }

    },[props.loaded360,favs,client])


    return (
        <div>
            {(previewImage) && <PreviewImage imageObj={previewImage} />}
        </div>
    );


}

export default withRouter(MainView);

