import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {buildingConst} from "../../constant/buildingConst";
import {appUtil} from "../../constant/appUtil";

function BuildingSvg(props) {

    const [paths,setPaths] = useState();
    useEffect(() => {

        createPaths();
        window.addEventListener('resize', handleResize)
        setTimeout(handleResize,500);

        var e = document.querySelector('body')
        var observer = new MutationObserver(function (event) {
            setTimeout(handleResize,100);
        })

        observer.observe(e, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false
        })

    },[])

    function handleResize() {
        var svgs = [...document.querySelectorAll('.svg__link')]
        var labels = [...document.querySelectorAll('.labels__item')]
        svgs.forEach((el,i) => {
            if(labels[i]) {
                labels[i].classList.add("labels__item--visible")


                    labels[i].style.left = getOffset(el).left + getOffset(el).width/2 + "px";
                    labels[i].style.top = getOffset(el).top + (getOffset(el).height/2) + "px";

            }


        })
    }

    function getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left ,
            top: rect.top,
            width : rect.width,
            height : rect.height
        };
    }

    function createPaths(){
        let currentPaths = [];
        let statusClass= "";


        let sortedFloor = props.floorStatuses.sort(appUtil.dynamicSort("propertyId"));
        console.log(buildingConst[props.buildingType].floorPath,sortedFloor)
        buildingConst[props.buildingType].floorPath.forEach(function(floor,i){


            switch(sortedFloor[i].status) {
                case 1:
                    statusClass = "";
                    break;
                case 2:
                    statusClass = "option";
                    break;
                case 3:
                    statusClass = "sold";
                    break;
                case 4:
                    statusClass = "not-released";
                    break;
            }
            
            

            if(sortedFloor[i].status === 3)
                currentPaths.push (<Link to={"#."} key={i} className={"svg__link " + statusClass} ><polygon className="cls-1" points={floor.points}></polygon></Link>)
            else
                currentPaths.push (<Link to={"/property-view/" + sortedFloor[i].propertyId} key={i} className={"svg__link " + statusClass} ><polygon className="cls-1" points={floor.points}></polygon></Link>)
        })

        setPaths(currentPaths);
    }


  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={buildingConst[props.buildingType].viewBox}>
            {paths}
        </svg>
    </div>


  );

}

export default BuildingSvg;