export const buildingConst = {
    "type_a": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"1739.45 777.28 1841.92 776.58 1841.92 650.83 1739.45 650.83 1739.45 777.28"},
            {status : 3, level: 1,points:"1540.53 777.08 1739.45 776.58 1739.45 650.83 1540.53 650.83 1540.53 777.08"},
            {status : 3, level: 2,points:"1329.09 650.83 1540.53 650.83 1540.53 776.58 1329.09 777.08 1329.09 650.83"},
            {status : 2, level: 3,points:"1152.4 777.08 1329.09 776.58 1329.09 650.83 1152.4 650.83 1152.4 777.08"},
            {status : 3, level: 4,points:"974.24 777.08 1152.4 776.58 1152.4 650.83 974.24 650.83 974.24 777.08"},
            {status : 3, level: 5,points:"800.21 648.78 803.45 650.83 974.24 650.83 974.24 776.58 800.12 777.08 800.21 648.78"},
            {status : 3, level: 6,points:"615.86 777.08 800.12 776.58 800.01 648.58 615.86 648.48 615.86 777.08"},
            {status : 3, level: 7,points:"383.52 777.08 615.86 776.58 615.86 648.48 383.52 648.48 383.52 777.08"},
            {status : 3, level: 8,points:"197.71 777.08 383.52 776.58 383.52 648.48 351.72 645.24 197.71 645.24 197.71 777.08"},
            {status : 3, level: 9,points:"197.71 645.24 84.04 645.24 84.04 777.08 197.71 776.58 197.71 645.24"},
            {status : 1, level: 10,points:"1624.75 424.53 1669.65 406.42 1679.37 406.42 1679.37 410.39 1794.66 410.39 1819.84 401.12 1836.62 401.12 1836.62 424.53 1841.48 424.53 1841.48 432.48 1897.58 432.48 1886.32 443.74 1840.16 456.33 1840.16 601.21 1910.83 601.21 1910.83 650.83 1788.48 650.83 1788.48 450.29 1624.75 450.29 1624.75 424.53"},
            {status : 3, level: 11,points:"1540.53 470.02 1529.34 470.02 1529.34 475.62 1464.26 475.62 1464.26 439.69 1461.61 439.69 1461.61 433.8 1534.64 396.99 1656.84 396.99 1656.84 403.47 1624.75 416.72 1624.75 450.29 1788.48 450.29 1788.48 650.83 1540.53 650.83 1540.53 470.02"},
            {status : 3, level: 12,points:"1355.3 475.62 1529.34 475.62 1529.34 470.02 1540.53 470.02 1540.53 650.83 1355.3 650.83 1355.3 475.62"},
            {status : 1, level: 13,points:"1128.85 403.77 1136.21 395.82 1259 395.82 1243.1 416.13 1243.1 449.7 1355.3 449.7 1355.3 650.83 1141.21 650.83 1141.21 403.77 1128.85 403.77"},
            {status : 1, level: 14,points:"974.24 650.83 1141.21 650.83 1141.21 404.77 1128.25 404.77 1128.25 409.36 974.24 409.36 974.24 650.83"},
            {status : 1, level: 15,points:"807.86 650.83 974.24 650.83 974.24 403.77 952.45 403.77 952.45 408.77 812.87 408.18 812.87 603.42 807.86 603.42 807.86 650.83"},
            {status : 1, level: 16,points:"615.86 648.48 597.02 648.48 597.02 473.56 597.02 447.35 695.08 447.35 695.08 415.55 682.42 401.12 682.71 396.99 681.83 393.46 806.39 393.46 812.87 408.18 812.87 603.42 807.86 603.42 807.86 650.83 803.45 650.83 800.21 648.48 615.86 648.48"},
            {status : 1, level: 17,points:"383.52 648.48 384.4 599.3 400.01 598.71 400.01 466.49 407.67 466.78 419.74 471.5 438.39 471.7 438.64 430.32 465.09 431.74 500.72 452.65 500.72 472.08 597.02 473.56 597.02 648.48 383.52 648.48"},
            {status : 1, level: 18,points:"465.09 431.74 401.19 393.16 269.85 391.4 269.85 446.17 152.06 446.17 152.06 645.24 351.72 645.24 384.11 648.48 384.4 599.3 400.01 598.71 399.72 508.6 400.01 466.49 407.67 466.78 419.74 471.5 438.39 471.7 438.64 430.32 465.09 431.74"},
            {status : 1, level: 19,points:"152.06 645.24 36.63 645.24 42.52 633.75 42.52 600.77 81.98 600.77 81.98 451.18 36.08 437.52 27.35 425.41 84.04 424.67 84.04 394.05 105.53 394.05 105.53 399.94 114.37 399.94 114.37 405.83 251.01 405.83 251.01 399.94 269.85 399.94 269.85 446.17 152.06 446.17 152.06 645.24"},


        ]
    },
    "type_b": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1552.72 435 1654 436 1654 559.84 1552.72 560.84 1552.72 435"},
            {status : 1, level: 1,points:"1552.72 435 1293.16 435 1293.16 560.07 1552.72 559.84 1552.72 435"},
            {status : 1, level: 2,points:"1293.16 436 1051.25 436 1051.25 560.07 1293.16 559.07 1293.16 436"},
            {status : 1, level: 3,points:"1051.25 436 913.88 436 913.88 560.25 1051.25 559.07 1051.25 436"},
            {status : 1, level: 4,points:"913.88 436 777 436 777 561.43 913.88 559.25 913.88 436"},
            {status : 1, level: 5,points:"777 436 539.89 436 539.89 558.48 777 560.43 777 436"},
            {status : 1, level: 6,points:"539.89 436 291 436 291 559.96 539.89 557.48 539.89 436"},
            {status : 1, level: 7,points:"192 444 192 559.96 291 558.96 291 436 282 436 282 444 192 444"},
            {status : 1, level: 8,points:"1593 263 1509.31 263 1509 238 1516.38 238 1516.38 242 1626 242 1626 238 1637 232 1653.75 232 1653.75 252.7 1692 252.7 1692 262 1654 279 1654 436 1552.72 435 1593 427 1593 263"},
            {status : 1, level: 9,points:"1408.6 436 1552.72 435 1593 427 1593 263 1509.31 263 1509 238 1500.8 243.42 1412 245 1370 277 1370 289 1383 289 1391 285 1399 285 1406 281 1408.6 436"},
            {status : 1, level: 10,points:"1204.53 436 1408.6 436 1406 281 1399 285 1391 285 1383 289 1370 289 1370 277 1366.19 277 1366.19 262 1353.38 262 1320 289 1216 289 1216 425 1205 432 1204.53 436"},
            {status : 1, level: 11,points:"1051.25 436 1204.53 436 1205 432 1216 425 1216 264.63 1133.63 264.63 1141 250 1140.92 246 1053.2 246 1053.46 254.02 1058 254.02 1058 264 1056 267 1056 427 1051.25 436"},
            {status : 1, level: 12,points:"913.88 436 1051.25 436 1056 427 1056 267 1058 264 1058 254.02 1053.46 254.02 1053 240 1045.95 240 1045.95 244 931.99 244 931.99 240 925.8 240 924.92 223 913.88 223 913.88 436"},
            {status : 1, level: 13,points:"777 436 913.88 436 913.88 223 908.13 223 908 240 901 240 901 244 788 246 788 241 779 241.21 779 256 769 256 778 276.99 777 436"},
            {status : 1, level: 14,points:"625.34 436 777 436 778 276.99 773.85 267 769 256 779 256 779 246.07 695 246 697 252 709.36 267 602 267 602 426 625.34 436"},
            {status : 1, level: 15,points:"421.63 436 625.34 436 602 426 602 286.27 523.48 286.27 484 264 470 264 470 288 431 287 421.63 283.6 421.63 436"},
            {status : 1, level: 16,points:"282 436 421.63 436 421.63 283.6 431 287 441 288 470 288 470 272.68 429 248 342.19 248 332.03 241.06 332.03 266 233 266 233 427 282 436"},
            {status : 1, level: 17,points:"192 444 146 438 149 435 149 428 152.83 428 152.83 397 190 397 190 267 146 267 146 257.25 194 257.25 194 241.06 202.14 241.06 202.14 245.2 324 245.2 324 241.06 332.03 241.06 332.03 266 233 266 233 427 282 436 282 444 192 444"},



        ]
    },
    "type_c": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1779.87 547.26 1779.87 436.65 1680.93 438.6 1680.93 547.26 1779.87 547.26"},
            {status : 1, level: 1,points:"1392.05 439.4 1680.93 438.3 1680.93 547.26 1392.05 547.26 1392.05 439.4"},
            {status : 1, level: 2,points:"1127.02 438.5 1392.05 439.4 1392.05 547.26 1127.02 549.47 1127.02 438.5"},
            {status : 1, level: 3,points:"1127.02 438.5 966.23 438.5 964.46 549.47 1127.02 549.47 1127.02 438.5"},
            {status : 1, level: 4,points:"801.91 438.5 801.91 549.47 964.46 549.47 966.23 438.5 801.91 438.5"},
            {status : 1, level: 5,points:"530.7 437.5 801.91 438.5 801.91 549.47 530.7 549.47 530.7 437.5"},
            {status : 1, level: 6,points:"530.7 437.5 231.21 436.1 231.21 549.47 530.7 549.47 530.7 437.5"},
            {status : 1, level: 7,points:"116.81 549.97 116.81 436.1 231.21 436.1 231.21 549.47 116.81 549.97"},
            {status : 1, level: 8,points:"1745.86 436.9 1832.88 436 1832.88 396.19 1782.52 396.19 1782.96 268.54 1835.97 247.33 1838.62 237.61 1763.97 237.61 1763.97 222.15 1754.69 222.15 1741.44 226.57 1634.99 227.01 1634.99 222.15 1624.83 222.15 1589.49 243.8 1745.86 243.8 1745.86 436.9"},
            {status : 1, level: 9,points:"1680.93 438.3 1745.86 436.9 1745.86 243.8 1626.6 243.8 1575.8 243.8 1575.8 239.82 1604.95 224.36 1604.51 219.5 1494.97 219.5 1413.25 274.72 1413.25 276.59 1392.05 276.49 1392.05 439.4 1680.93 438.3"},
            {status : 1, level: 10,points:"1392.05 439.4 1392.05 276.49 1384.98 276.49 1384.98 280.46 1362.01 280.46 1362.01 258.82 1345.22 258.82 1322.7 276.04 1322.7 241.15 1227.73 241.15 1227.73 219.5 1119.51 219.5 1119.51 231.43 1131.43 231.43 1131.43 241.15 1127.02 257.93 1127.02 438.5 1392.05 439.4"},
            {status : 1, level: 11,points:"1127.02 438.5 1127.02 257.93 1131.43 241.15 1131.43 231.43 1119.51 231.43 1119.51 219.5 1108.02 219.5 1108.02 223.48 982.13 223.48 982.13 217.74 975.06 217.74 975.06 197.42 966.23 197.42 966.23 438.5 1127.02 438.5"},
            {status : 1, level: 12,points:"801.91 438.5 966.23 438.5 966.23 197.42 953.86 197.42 953.86 217.74 945.91 217.74 945.91 222.15 817.37 222.15 817.37 213.76 807.65 213.76 807.65 228.78 797.05 228.78 797.05 238.5 801.91 250.87 801.91 438.5"},
            {status : 1, level: 13,points:"801.91 438.5 801.91 250.87 797.05 238.5 797.05 228.78 807.65 228.78 807.65 213.76 695.02 213.76 695.02 237.61 609.32 236.29 609.32 275.16 591.65 275.16 591.65 271.19 574.21 253.74 554.11 253.74 554.11 272.07 530.7 272.07 530.7 437.5 801.91 438.5"},
            {status : 1, level: 14,points:"530.7 272.07 530.7 437.5 169.81 437.5 169.81 234.52 287.75 234.52 287.75 211.55 421.15 211.55 496.24 267.65 496.24 272.95 530.7 272.07"},
            {status : 1, level: 15,points:"68.22 237.17 68.22 225.69 141.99 225.69 141.99 211.55 152.15 211.55 152.15 216.85 277.59 216.85 277.59 211.55 287.75 211.55 287.75 234.52 169.81 234.52 169.81 436.1 68.22 436.1 68.22 237.17"},


        ]
    },

    "type_d": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1919.5 721.5 1877.9 534 1480.07 518.5 1461.5 557.5 1520.5 681.5 1564.5 699.5 1619.06 721.5 1919.5 721.5"},
            {status : 1, level: 1,points:"1619.5 721.5 1564.5 699.5 1520.5 681.5 1451.5 537.5 1261.5 518.5 1213.5 643.5 1258.5 688.5 1291.5 721.5 1619.5 721.5"},
            {status : 1, level: 2,points:"1221.5 710.5 959.5 695.5 1101.19 515.5 1181.5 638.5 1221.5 710.5"},
            {status : 1, level: 3,points:"916.5 697.5 578.5 709.5 622.5 663.5 640.5 644.5 731.5 530.5 782.5 517.5 916.5 697.5"},
            {status : 1, level: 4,points:"257.98 709.5 578.5 709.5 640.5 644.5 611.24 517.5 335.5 537.5 257.98 709.5"},
            {status : 1, level: 5,points:"257.5 709.5 169.5 709.5 2.5 709.5 55.5 531.5 459.5 518.5 465.96 528.04 335.5 537.5 257.5 709.5"},

        ]
    },

    "type_e": {
        viewBox: "0 0 1920 1080",
        floorPath: [

            {status : 1, level: 0,points:"1682.85 611.5 1682.85 719.5 1599.22 720.5 1599.22 611.5 1682.85 611.5"},
            {status : 1, level: 1,points:"1599.22 611.5 1599.22 720.5 1451.68 719.5 1451.68 611.5 1599.22 611.5"},
            {status : 1, level: 2,points:"1451.68 611.5 1451.68 719.5 1251.44 720.5 1251.44 611.5 1451.68 611.5"},
            {status : 1, level: 3,points:"1251.44 611.5 1251.44 720.5 1098.6 719.5 1098.6 611.5 1251.44 611.5"},
            {status : 1, level: 4,points:"1098.6 611.5 1098.6 719.5 957.25 720.5 957.25 611.5 1098.6 611.5"},
            {status : 1, level: 5,points:"957.25 611.5 957.25 720.5 816.79 719.5 816.79 611.5 957.25 611.5"},
            {status : 1, level: 6,points:"816.79 611.5 816.79 719.5 662.5 720.5 662.5 611.5 816.79 611.5"},
            {status : 1, level: 7,points:"459.29 611.5 662.5 611.5 662.5 720.5 459.29 719.5 459.29 611.5"},
            {status : 1, level: 8,points:"305.57 611.5 459.29 611.5 459.29 719.5 305.57 720.5 305.57 611.5"},
            {status : 1, level: 9,points:"222.5 719.5 222.5 611.5 305.57 611.5 305.5 720.5 222.5 719.5"},
            {status : 1, level: 10,points:"1731.5 429.5 1731.5 611.5 1653.69 611.5 1653.69 438.49 1503.07 438.49 1503.07 404.5 1533.5 392.5 1731.5 392.5 1731.5 429.5"},
            {status : 1, level: 11,points:"1451.68 611.5 1653.69 611.5 1653.69 438.49 1503.07 438.49 1503.07 404.5 1533.5 392.5 1533.5 387.5 1434.6 387.5 1366.5 418.5 1366.5 427.43 1381.15 421.28 1395.73 421.28 1395.73 436.28 1399.5 436.28 1399.5 457.5 1412.5 457.5 1427.5 453.5 1435.5 453.5 1451.68 448.65 1451.68 611.5"},
            {status : 1, level: 12,points:"1451.68 611.5 1451.68 448.65 1435.5 453.5 1427.5 453.5 1412.5 457.5 1399.5 457.5 1399.5 436.28 1395.73 436.28 1395.73 421.28 1381.15 421.28 1342.5 437.5 1276.03 437.5 1276.03 611.5 1451.68 611.5"},
            {status : 1, level: 13,points:"1276.03 611.5 1276.03 437.5 1237.6 437.5 1237.6 416.4 1220.81 416.4 1196.5 433.5 1196.5 438.05 1179.5 438.5 1179.5 403.59 1193.5 392.5 1193.5 385.5 1093.16 385.5 1086.53 396.08 1093.5 396.08 1093.5 417.28 1104.5 417.28 1104.5 427.5 1093.5 439.37 1093.5 452.5 1098.6 449.5 1098.6 611.5 1276.03 611.5"},
            {status : 1, level: 14,points:"1098.6 611.5 1098.6 449.5 1093.5 452.5 1093.5 439.37 1104.5 427.5 1104.5 417.28 1093.5 417.28 1093.5 396.08 1086.53 396.08 1086.53 400.94 973.5 400.5 973.5 396.08 966.38 396.08 966.38 378.85 957.25 378.85 957.25 611.5 1098.6 611.5"},
            {status : 1, level: 15,points:"816.79 611.5 816.79 449.97 820.5 451.5 820.5 439.37 813.5 426.5 813.5 418.61 820.5 418.61 820.5 396.08 827.68 396.08 827.68 400.5 940.5 400.5 940.5 396.08 947.5 396.5 947.5 378.85 957.25 378.85 957.25 611.5 816.79 611.5"},
            {status : 1, level: 16,points:"816.79 611.5 816.79 449.97 820.5 451.5 820.5 439.37 813.5 426.5 813.5 418.61 820.5 418.61 820.5 396.08 827.68 396.08 820.59 385.5 719.9 385.5 719.9 391.22 733.5 404.5 733.5 436.5 719.9 436.72 687.22 414.63 673.52 414.63 673.5 436.5 638.19 437.16 638.19 611.5 816.79 611.5"},
            {status : 1, level: 17,points:"459.29 611.5 638.19 611.5 638.19 417.73 544.5 417.73 544.5 420.5 531.29 414.63 514.95 414.63 514.95 436.28 510.5 436.28 510.5 457.5 497.5 457.5 484.5 453.5 475.5 453.5 459.29 446.88 459.29 611.5"},
            {status : 1, level: 18,points:"459.29 611.5 459.29 446.88 475.5 453.5 484.5 453.5 497.5 457.5 510.5 457.5 510.5 436.28 514.95 436.28 514.95 414.63 531.29 414.63 544.5 420.5 544.5 416.63 477.5 385.5 374.04 385.5 374.04 391.22 403.5 401.38 403.5 435.25 255.5 435.25 255.5 611.5 459.29 611.5"},
            {status : 1, level: 19,points:"174.53 611.5 174.53 593.97 178.5 593.97 178.5 566.5 222.5 566.5 222.5 419.5 225.18 419.5 225.18 408.74 232.5 408.74 232.5 394.5 248.45 394.5 258.5 398.5 359.5 398.5 359.5 394.5 368.3 394.5 403.5 408.5 403.5 435.25 255.5 435.25 255.5 611.5 174.53 611.5"},

        ]
    },
    "type_f": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"1687.97,695.3 1687.97,570.17 1784.32,570.17 1784.32,696.3"},
            {status : 3, level: 1,points:"1518.07,570.17 1687.97,570.17 1687.97,695.3 1518.07,696.3"},
            {status : 3, level: 2,points:"1290.94,570.17 1518.07,570.17 1518.07,696.3 1290.94,695.3"},
            {status : 2, level: 3,points:"1105.24,696.3 1290.94,695.3 1290.94,570.17 1122.33,570.17 1105.24,570.17"},
            {status : 3, level: 4,points:"1105.24,569.17 1105.24,696.3 948.55,695.3 948.55,569.17"},
            {status : 3, level: 5,points:"789.01,696.3 948.55,695.3 948.55,569.17 789.01,569.17"},
            {status : 3, level: 6,points:"789.01,569.17 789.01,696.3 607.45,695.3 607.45,569.14"},
            {status : 3, level: 7,points:"371.29,696.3 607.45,695.3 607.45,569.14 370.29,569.14"},
            {status : 3, level: 8,points:"194.36,569.17 370.29,569.17 371.29,696.3 194.36,695.3"},
            {status : 3, level: 9,points:"99.05,684.17 99.05,569.17 194.36,569.17 194.36,695.3 99.05,696.3"},
            {status : 3, level: 10,points:"1784.32,570.17 1861.76,570.17 1861.76,518.41 1841.04,518.41 1841.04,348.25 1784.32,348.25 1784.32,338.67 1774.47,338.67 1774.47,321.06 1758.16,321.06 1742.1,327.27 1610.53,327.27 1610.53,370.53 1747.8,370.53 1747.8,570.17"},
            {status : 3, level: 11,points:"1688,570.2 1747.8,570.2 1747.8,370.5 1610.5,370.5 1610.5,326.8 1603,326.8 1580.5,341.7 \n" +
                    "\t\t1580.5,313 1496.6,312 1419.7,347 1419.7,354 1438.3,345.9 1448.1,345.9 1448.1,389.4 1498.6,388.4 1518.1,383.2 1518.1,570.2"},
            {status : 3, level: 12,points:"1313.47,370.01 1393.5,370.01 1393.5,365.35 1438.3,345.92 1448.14,345.92 1448.14,389.4 1498.65,388.4 1518.07,383.22 1518.07,570.17 1313.47,570.17"},
            {status : 1, level: 13,points:"1290.94,570.17 1313.47,570.17 1313.47,370.01 1273.06,370.01 1271.06,367.16 1270.47,361.46 1270.47,345.14 1252.86,345.14 1226.45,365.09 1226.45,369.23 1204.69,369.23 1204.69,330.9 1220.49,316.91 1220.49,310.7 1102.65,310.7 1095.65,322.35 1100.58,322.35 1100.58,346.44 1116.37,346.44 1116.37,350.06 1113.23,350.06 1113.23,357.06 1116.37,357.06 1116.37,359.68 1105.24,371.63 1105.24,570.17"},
            {status : 1, level: 14,points:"1105.24,569.17 948.55,569.17 948.55,301.63 957.87,301.63 957.87,320.8 966.94,320.8 966.94,326.24 1093.84,326.24 1093.84,322.35 1100.58,322.35 1100.58,346.44 1116.37,346.44 1116.37,350.06 1113.23,350.06 1113.23,357.06 1116.37,357.06 1116.37,359.68 1105.24,371.63"},
            {status : 1, level: 15,points:"789.01,569.17 789.01,370.78 779.13,358.91 779.13,345.4 796.52,345.4 796.52,319.5 799.88,319.5 799.88,325.2 929.12,325.2 929.12,321.06 935.6,321.06 935.6,301.63 948.55,301.63 948.55,569.17"},
            {status : 1, level: 16,points:"578.45,569.17 789.01,569.17 789.01,370.78 779.42,358.61 779.42,345.4 796.52,345.4 796.52,319.5 789.53,309.4 673.24,309.4 674.27,317.43 690.59,330.9 690.59,364.31 668.83,364.31 636.72,343.33 620.66,343.33 620.66,368.64 578.45,368.97"},
            {status : 1, level: 17,points:"444.03,343.33 444.03,393.06 418.39,393.06 401.55,387.36 389.9,387.36 370.29,382.18 370.29,569.17 578.45,569.17 578.45,368.97 503.34,368.97 503.34,348.32 469.67,347.99 458.53,343.33"},
            {status : 1, level: 18,points:"444.03,388.1 444.03,343.33 458.53,343.33 469.67,347.99 469.67,344.88 393.01,309.4 274.13,309.4 274.13,315.88 309.87,329.6 309.87,336.08 266.36,321.06 266.36,367.64 136.6,367.64 136.6,569.17 370.29,569.17 370.29,382.18 389.9,387.36 401.55,387.36 418.39,393.06 444.03,393.06"},
            {status : 1, level: 19,points:"136.6,569.2 136.6,367.7 266.4,367.7 266.4,318.7 256.5,318.7 256.5,324.4 128.8,324.4 \n" +
                    "\t\t128.8,318.7 110.4,318.7 110.4,335.8 99.1,335.8 99.1,346.2 46.2,346.2 46.2,355.5 43.1,355.5 43.1,358.6 99.1,369.7 99.1,517.4 \n" +
                    "\t\t49.7,517.4 48.7,550.9 40.5,560.1 46,560.1 46,566.6 40.5,568.9"},


        ]
    },
    "type_g": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1740.81,639.11 1846.73,640.11 1846.73,515.04 1740.81,515.04"},
            {status : 1, level: 1,points:"1425.35,640.11 1740.81,639.11 1740.81,515.04 1425.35,515.04"},
            {status : 1, level: 2,points:"1133.73,515.04 1425.35,515.04 1425.35,640.11 1133.73,639.11"},
            {status : 1, level: 3,points:"965.64,640.11 1133.73,639.11 1133.73,515.04 965.64,515.04"},
            {status : 1, level: 4,points:"797.29,639.11 965.64,640.11 965.64,515.04 797.29,515.04"},
            {status : 1, level: 5,points:"499.45,640.11 797.29,639.11 797.29,515.04 499.45,515.04"},
            {status : 1, level: 6,points:"177.27,639.11 499.45,640.11 499.45,514.53 177.27,514.53"},
            {status : 1, level: 7,points:"67.45,515.04 67.45,640.11 177.27,639.11 177.27,515.04"},
            {status : 1, level: 8,points:"1802.45,515.04 1884.81,515.04 1884.81,465.83 1849.36,465.83 1849.36,315.88 1906.56,304.74 1906.56,302.89 1902.16,302.89 1902.16,294.9 1906.56,294.9 1906.56,293.05 1849.32,293.05 1849.32,282.47 1829.12,282.47 1829.12,266.93 1820.06,266.93 1792.09,271.33 1688.75,271.33 1688.75,266.93 1679.94,266.93 1679.94,316.14 1802.45,316.14"},
            {status : 1, level: 9,points:"1740.81,515.04 1802.45,515.04 1802.45,316.14 1679.94,316.14 1679.94,266.93 1658.45,274.18 1658.45,258.64 1537.76,258.64 1454.1,296.71 1454.1,301.37 1456.95,301.37 1456.95,338.41 1433.64,338.41 1425.35,331.68 1425.35,515.04"},
            {status : 1, level: 10,points:"1425.35,515.04 1425.35,331.68 1397.12,331.68 1397.12,324.42 1394.01,324.42 1394.01,301.12 1377.18,301.12 1345.58,315.62 1231.11,315.62 1231.11,279.1 1248.2,264.34 1248.2,258.64 1126.99,258.64 1121.29,266.41 1129.58,266.41 1129.58,288.42 1144.09,288.42 1144.09,300.08 1129.58,315.88 1129.58,332.71 1133.73,329.35 1133.73,515.04"},
            {status : 1, level: 11,points:"1133.73,515.04 1133.73,329.35 1129.58,332.71 1129.58,315.88 1144.09,300.08 1144.09,288.42 1129.58,288.42 1129.58,266.41 1121.29,266.41 1116.37,271.07 982.73,271.07 982.73,266.41 976.52,266.41 976.52,241.03 965.64,241.03 965.64,515.04"},
            {status : 1, level: 12,points:"797.29,515.04 965.64,515.04 965.64,241.03 952.69,241.03 952.69,265.63 944.92,265.63 944.92,270.04 809.21,270.04 797.42,258.25 797.42,288.68 786.16,288.68 786.16,301.37 797.29,314.84"},
            {status : 1, level: 13,points:"797.29,314.84 786.16,301.37 786.16,288.68 797.29,288.68 797.29,258.38 676.09,258.38 676.09,264.34 691.63,278.32 691.63,294.6 499.45,294.6 499.45,514.53 797.29,515.04"},
            {status : 1, level: 14,points:"117.96,514.53 499.45,514.53 499.45,294.64 463.45,294.64 382.65,256.83 258.07,256.83 258.07,262.53 295.88,276.25 295.88,284.02 240.98,264.34 235.02,264.34 235.02,301.12 242.53,303.71 242.53,313.81 117.96,313.81"},
            {status : 1, level: 15,points:"15.4,515.04 15.4,463.76 64.86,463.76 64.86,313.03 7.37,300.86 12.29,298.78 12.29,292.57 7.37,289.72 67.19,289.72 67.19,279.1 86.36,279.1 86.36,264.34 114.07,264.34 117.57,267.83 230.62,267.83 230.62,264.34 235.02,264.34 235.02,301.12 242.53,303.71 242.53,313.81 117.96,313.81 117.96,514.53 15. ,515.04"},




        ]
    },
    "type_h": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1616.23,586.78 1712.83,586.78 1712.83,697.97 1616.23,696.97"},
            {status : 1, level: 1,points:"1466.27,697.97 1616.23,696.97 1616.23,586.78 1466.27,586.78"},
            {status : 1, level: 2,points:"1264.26,696.97 1466.27,697.97 1466.27,586.78 1264.26,586.78"},
            {status : 1, level: 3,points:"1103.65,586.78 1264.26,586.78 1264.26,696.97 1103.65,697.97"},
            {status : 1, level: 4,points:"1103.65,586.78 1103.65,697.97 956.83,696.97 956.83,586.78"},
            {status : 1, level: 5,points:"813.61,697.97 956.83,696.97 956.83,586.78 813.61,586.78"},
            {status : 1, level: 6,points:"813.61,586.78 813.61,697.97 652.26,696.97 652.26,586.78"},
            {status : 1, level: 7,points:"440.66,697.97 652.26,696.97 652.26,586.78 440.66,586.78"},
            {status : 1, level: 8,points:"280.6,586.78 440.66,586.78 440.66,697.97 280.6,696.97"},
            {status : 1, level: 9,points:"183.19,697.97 280.6,696.97 280.6,586.78 183.19,586.78"},
            {status : 1, level: 10,points:"1712.83,385.77 1712.83,373.37 1706.88,373.37 1706.88,355.24 1689.01,355.24 1689.01,357.83 1658.45,363.27 1574.79,363.27 1574.79,358.61 1567.28,358.61 1560.03,360.17 1560.03,409.89 1664.66,409.89 1664.66,586.78 1712.83,586.78 1712.83,581.6 1755.83,581.6 1755.83,573.32 1753.24,573.32 1753.24,567.62 1750.13,567.62 1750.13,538.09 1712.83,538.09 1712.83,403.68 1757.64,396.42 1757.64,393.83 1753.5,393.83 1753.5,388.62 1757.64,388.62 1757.64,385.77"},
            {status : 1, level: 11,points:"1616.23,586.78 1664.66,586.78 1664.66,409.89 1560.03,409.89 1560.03,346.96 1454.36,346.96 1392.2,371.82 1392.46,378.04 1415.77,378.04 1415.77,398.76 1419.4,398.76 1419.4,425.43 1442.96,420.25 1451.77,420.25 1466.27,416.37 1466.27,586.78"},
            {status : 1, level: 12,points:"1466.27,586.78 1466.27,416.37 1451.77,420.25 1442.96,420.25 1419.4,425.43 1419.4,398.76 1415.77,398.76 1415.77,378.04 1392.46,378.04 1362.16,390.73 1362.16,409.93 1282.39,409.89 1282.39,586.78"},
            {status : 1, level: 13,points:"1264.26,586.78 1282.39,586.78 1282.39,409.89 1250.79,409.89 1250.79,398.24 1248.46,398.24 1248.46,377.52 1233.44,377.52 1210.65,390.21 1190.71,390.21 1190.71,363.27 1204.43,352.4 1204.95,345.4 1096.95,345.4 1090.22,356.8 1094.36,356.8 1094.36,383.73 1108.09,383.73 1108.09,393.58 1098.24,402.38 1098.24,418.96 1102.65,416.37 1103.65,586.78"},
            {status : 1, level: 14,points:"1102.65,416.37 1098.24,418.96 1098.24,402.38 1108.09,393.58 1108.09,383.73 1094.36,383.73 1094.36,356.8 1090.22,356.8 1086.33,360.68 973.15,360.68 973.15,356.8 964.6,356.8 964.6,337.63 954.83,337.63 956.83,586.78 1103.65,586.78"},
            {status : 1, level: 15,points:"954.83,337.63 944.14,337.63 944.14,356.8 938.96,356.8 938.96,361.2 823.19,361.2 815.94,352.14 815.94,383.22 805.06,383.22 805.06,393.58 813.61,401.35 813.61,586.78 956.83,586.78"},
            {status : 1, level: 16,points:"632.06,586.78 813.61,586.78 813.61,401.35 805.06,393.58 805.06,383.22 815.94,383.22 815.94,352.14 811.8,345.4 705.35,345.4 705.35,351.88 718.3,362.24 718.3,397.2 697.84,397.2 697.84,390.73 673.24,375.96 656.66,375.96 656.66,408.34 632.06,408.34"},
            {status : 1, level: 17,points:"440.66,586.78 440.66,416.11 455.17,418.96 464.49,419.47 478.73,423.1 494.01,423.1 494.01,374.41 509.04,374.41 543.48,386.84 632.06,386.84 632.06,586.78"},
            {status : 1, level: 18,points:"237.87,586.78 440.66,586.78 440.66,416.11 455.17,418.96 464.49,419.47 478.73,423.1 494.01,423.1 494.01,374.41 509.04,374.41 509.04,370.27 450.76,345.4 340.95,345.4 340.95,349.81 369.18,359.13 369.18,366.38 338.36,356.28 338.36,406.27 237.87,406.27"},
            {status : 1, level: 19,points:"183.19,586.78 183.19,582.64 137.38,582.64 141.01,580.83 141.01,567.88 145.15,567.88 145.15,538.09 183.19,538.09 183.19,392.09 140.23,391.24 143.86,388.91 143.86,382.96 140.23,382.96 144.12,379.33 183.19,379.33 183.19,359.13 194.88,359.13 194.88,337.89 210.94,337.89 210.94,357.32 217.67,357.32 217.67,360.94 327.22,360.94 327.22,356.28 338.36,356.28 338.36,406.27 237.87,406.27 237.87,586.78"},


        ]
    },
    "type_i": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1911.98 761.57 1763.58 329.57 1277.97 476.42 1189.67 622.49 1254.66 724.27 1408.48 767.36 1911.98 761.57"},
            {status : 1, level: 1,points:"701.74 780.61 978.83 292.27 1254.66 724.27 1288.34 785.03 701.74 780.61"},
            {status : 1, level: 2,points:"143.58 749.14 214.28 319.47 741.08 463.21 804.01 589.86 795.46 609.28 581.59 795.63 143.58 749.14"},

        ]
    },
}