import ProjectMarker from "../components/ProjectMarker";
import GoogleMap from "google-map-react";
import React from "react";

const appSettings = {
    projectName: "Kislands",
    currencyUnit: "£",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: true,
    priceFilter: {
        unit: 10000,
        min: 120000,
        max: 380000,
    },
    showIntroVideo : false,
    is360External : false,
    clientVerification : false,
    showRegisterClientPage : true,
    botSalesRep : "online@kensingtoncyprus.com"
};

const appConst = {
    
    menu: {
        gallery : [
            {id:1, title:'Exterior',isExternal : false,link: '/gallery/exterior'},
            {id:2, title:'Interior',isExternal : false,link: '/gallery/interior'},

        ],

        location_category : [
            {id : "shopping",title : "Shopping"},
            {id : "social",title : "Social"},
            {id : "health",title : "Health"},
            {id : "public_place",title : "Public"},
            {id : "transportation",title : "Transportation"},
            {id : "schools",title : "Schools"},
        ],
        location : {
            center : [
                {id:0, title:"", lat: 35.40700115158713,  lng: 33.779446078333834}
            ],
            shopping : [
                {id:1,title:'Alpet - Cankadı Petrol Station',link:"/location/shopping/1", lat:35.3932572699027, lng:33.74584027861549 },
                {id:2,title:'Arkan Adışanlı Pharmacy',link:"/location/shopping/2", lat:35.39267481724256, lng:33.74502163623393 },
                {id:3,title:'Davlos Market 2',link:"/location/shopping/3", lat:35.37300175170056, lng:33.71160869563166 },
                {id:4,title:'Azzder Supermarket',link:"/location/shopping/4", lat:35.390076971409854, lng:33.741340705760145 },
                {id:5,title:'Metran market',link:"/location/shopping/5", lat:35.39224628893206, lng:33.74300329140391 },
                {id:6,title:'Senmar Supermarket',link:"/location/shopping/6", lat:35.37420363472105, lng:34.03196215214113 },

            ],

            social :  [
                {id:7,title:'Zambak Holiday Village & Restaurant',link:"/location/social/7", lat:35.411433809085025,  lng:33.81864779796135},
                {id:8,title:'Ardıç Agacı Holiday Village & Restaurant',link:"/location/social/8", lat:35.412322115394325,  lng:33.81488828446703},
                {id:9, title:'Royal Green Garden Centre', link: '/location/social/9', lat:35.36120322733912,  lng:33.6696489339659},
                {id:10, title:'Eagle’ s Nest Restaurant & Beach Bar', link: '/location/social/10', lat:35.36463265333721,  lng:33.67047612393933},
                {id:11,title:'Çiftlikdüzü Restaurant',link:"/location/social/11", lat:35.407569095862826,  lng:33.7886396833979},
                {id:12,title:'Kaplıca Restaurant & Beach & Camping Area',link:"/location/social/12", lat:35.42566859081056,  lng:33.896269165681375},
                {id:13, title:'Kantara Restaurant',link:"/location/social/13", lat:35.38792722969295, lng:33.8985232595364},
                {id:14, title:'K Café',link:"/location/social/14", lat:35.407053213734486, lng:33.77924889915181},
                {id:15, title:'Korineum Golf & Beach Resort',link:"/location/social/15", lat:35.32563573979336, lng:33.51650699825214},
                {id:16, title:'Alagadi Turtle Beach',link:"/location/social/16", lat:35.33423131754661, lng:33.49140694221157}

            ],

            public_place : [
                {id:17,title:'Minia Cyprus',link:"/location/public_place/17", lat:35.404941351922076, lng:33.78254162146794 },
                {id:18,title:'Kantara Castle',link:"/location/public_place/18", lat:35.40621307181338, lng:33.923118809828765 },
            ],

            health : [
                {id:19,title:'Girne Üniversitesi Hastenesi',link:"/location/health/19", lat:35.335443068570605, lng:33.340501920555965 },
                {id:20,title:'AKÇİÇEK Hospital',link:"/location/health/20", lat:35.337729194819296, lng:33.32556298460987 },

            ],
            transportation : [
                {id:21,title:'Ercan International Airport',link:"/location/transportation/21", lat:35.157733827995756, lng:33.502726886094706},
                {id:22,title:'Larnaca International Airport',link:"/location/transportation/22", lat:34.87123742085969, lng:33.60783098596328,},
            ],
            schools : [
                {id:23,title:'Eastern Mediterranean Unıversity',link:"/location/schools/23", lat:35.14237707611974, lng:33.91165306974953 },
                {id:24,title:'Near East University',link:"/location/schools/24", lat:35.22672195380879, lng:33.32639592883565 },
                {id:25,title:'Cyprus International University',link:"/location/schools/25", lat:35.220918617622644, lng:33.41663772883565 },
                {id:26,title:'Girne American University',link:"/location/schools/26", lat:35.333249804154335, lng:33.27531744658276 },
                {id:27,title:'English School of Kyrenia',link:"/location/schools/27", lat:35.31641851419015, lng:33.34336568816986},
                {id:28,title:'Doğa Koleji Girne',link:"/location/schools/28", lat:35.32962944988623, lng:33.35029619915762}
            ]

        }


    },
    gallery : {

        exterior : [
            {caption:'Exterior 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:'Exterior 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:'Exterior 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:'Exterior 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:'Exterior 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:'Exterior 6', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:'Exterior 7', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:'Exterior 8', source: require("../img/gallery/exterior/i8.jpg")},



        ],
        interior : [
            {caption:'Interior 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:'Interior 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:'Interior 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:'Interior 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:'Interior 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:'Interior 6', source: require("../img/gallery/interior/i6.jpg")},
            {caption:'Interior 7', source: require("../img/gallery/interior/i7.jpg")},
            {caption:'Interior 8', source: require("../img/gallery/interior/i8.jpg")},
            {caption:'Interior 9', source: require("../img/gallery/interior/i9.jpg")},
            {caption:'Interior 10', source: require("../img/gallery/interior/i10.jpg")},
            {caption:'Interior 11', source: require("../img/gallery/interior/i11.jpg")},
            {caption:'Interior 12', source: require("../img/gallery/interior/i12.jpg")},
            {caption:'Interior 13', source: require("../img/gallery/interior/i13.jpg")},
            {caption:'Interior 14', source: require("../img/gallery/interior/i14.jpg")},
            {caption:'Interior 15', source: require("../img/gallery/interior/i15.jpg")},

        ],

        facilities : [
            {id:1,caption:'Area 1',vr:"https://multiplus.wizio.co.uk/vr/area_1/index.htm", thumb: require("../img/gallery/facilities/thumb/i1.jpg")},
            {id:2,caption:'Area 2',vr:"https://multiplus.wizio.co.uk/vr/area_2/index.htm", thumb: require("../img/gallery/facilities/thumb/i2.jpg")},
            {id:3,caption:'Area 3',vr:"https://multiplus.wizio.co.uk/vr/area_3/index.htm", thumb: require("../img/gallery/facilities/thumb/i3.jpg")},
            {id:4,caption:'Terrace 1',vr:"https://multiplus.wizio.co.uk/vr/terrace_1/index.htm", thumb: require("../img/gallery/facilities/thumb/i4.jpg")},
            {id:5,caption:'Terrace 2',vr:"https://multiplus.wizio.co.uk/vr/terrace_2/index.htm", thumb: require("../img/gallery/facilities/thumb/i5.jpg")},
            {id:6,caption:'Villas',vr:"https://multiplus.wizio.co.uk/vr/villas/index.htm", thumb: require("../img/gallery/facilities/thumb/i6.jpg")},
            {id:7,caption:'Entrance',vr:"https://multiplus.wizio.co.uk/vr/entrance/index.htm", thumb: require("../img/gallery/facilities/thumb/i7.jpg")},
            {id:8,caption:'Drone',vr:"https://multiplus.wizio.co.uk/vr/drone/index.htm", thumb: require("../img/gallery/facilities/thumb/i7.jpg")},

        ],



    },
    brochure : [

    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        not_released : 4
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Not Released";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    lead_status : {
        new : 1,
        active : 2,
        lost : 3,
        inactive : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};